import { useEffect, useState } from "react";
import styles from "./carousel.module.css";
import { BiSolidCircle } from "react-icons/bi";
import React from "react";

const CarouselIndicators = ({ images, activeIndex, onClick }) => {
  return (
    <div className={styles.carousel__indicators}>
      {images.map((_, index) => (
        <span
          key={index}
          className={`${styles.carousel__indicator} ${
            index === activeIndex ? styles.balls : ""
          }`}
          onClick={() => onClick(index)}
        >
          <BiSolidCircle />
        </span>
      ))}
    </div>
  );
};

const Carousel = ({ images, children, interval = 5000 }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  // const interval = 5000;
  const nextSlide = () => {
    setActiveIndex((prevState) =>
      prevState === images.length - 1 ? 0 : prevState + 1
    );
  };

  const prevSlide = () => {
    setActiveIndex((prevState) =>
      prevState === 0 ? images.length - 1 : prevState - 1
    );
  };

  useEffect(() => {
    const intervalId = setInterval(nextSlide, interval);

    return () => clearInterval(intervalId);
  }, [interval]);

  const goToSlide = (index) => {
    setActiveIndex(index);
  };
  return (
    <>
      <div className={styles.carousel}>
        <button
          onClick={prevSlide}
          className={`${styles.carousel__btn} ${styles.carousel__btn_prev}`}
        >
          &lt;
        </button>
        {/* <CarouselCard
          service={images[activeIndex].service}
          servicedetail={images[activeIndex].serviceDetail}
        /> */}
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, { details: images[activeIndex] });
        })}
        <button
          onClick={nextSlide}
          className={`${styles.carousel__btn} ${styles.carousel__btn_next}`}
        >
          &gt;
        </button>
        <CarouselIndicators
          images={images}
          activeIndex={activeIndex}
          onClick={goToSlide}
        />
      </div>
    </>
  );
};

export default Carousel;
