import SolarProject from "./SolarProject/solarProject";

const Strength = () => {
  return (
    <>
      <SolarProject />
    </>
  );
};

export default Strength;
