import React from "react";
import styles from "./backgroundVideo.module.css";
import video from "../../static/videos/homeBg1.mp4";
import Carousel from "../Carousel/carousel";
import CarouselCard from "../Carousel/CarouselCard/carouselCard";

const VideoBackground = () => {
  const images = [
    {
      service: "Network Services",
      serviceDetail:
        "RVSM provides high-speed and reliable Internet Services to the customer.",
      path: "/services/broadband/plans",
    },
    {
      service: "Web Development",
      serviceDetail:
        "We design and develop websites that help our clients grow, innovate, and transform.",
      path: "services/web/development",
    },
    {
      service: "Surveillance System",
      serviceDetail:
        "RVSM has Expertise in many big projects for supply and installation of CCTV System and Home Security System.",
      path: "services/cctv/Surveillance",
    },
    {
      service: "Telecom Projects",
      serviceDetail:
        "Our expertise extends to executing telecom projects, encompassing planning, designing, implementation, and maintenance.",
      path: "services/telecom/projects",
    },

    {
      service: "Network Management",
      serviceDetail:
        "We provide comprehensive network management services to optimize performance, monitor traffic, and ensure the smooth operation of telecom networks.",
      path: "/services/network/management",
    },
  ];
  return (
    <div className={styles.video_background_container}>
      <div className={styles.video_background}>
        <video autoPlay loop muted>
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className={styles.content}>
        <Carousel images={images}>
          <CarouselCard />
        </Carousel>
      </div>
    </div>
  );
};

export default VideoBackground;
