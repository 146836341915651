import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { CustomPlanContext } from "./store/getPlansContext";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CustomVisitCounterContext } from "./store/getVisitCounterContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CustomPlanContext>
      <CustomVisitCounterContext>
        <App />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </CustomVisitCounterContext>
    </CustomPlanContext>
  </React.StrictMode>
);
