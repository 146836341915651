import { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

export const visitCounterContext = createContext();

export const CustomVisitCounterContext = ({ children }) => {
  const [pageVisit, setPageVisit] = useState({
    visitCounts: "",
  });

  async function updateCounter(type) {
    try {
      if (sessionStorage.getItem("visit") === null) {
        const res = await fetch(
          `${process.env.REACT_APP_API_PATH}/api/visitCounter/getCounts?` + type
        );
        const data = await res.json();
        setPageVisit({
          ...pageVisit,

          visitCounts: data.visitCounts,
        });
        sessionStorage.setItem("visit", "x");
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
  useEffect(() => {
    updateCounter("type=visit-pageview");

    return sessionStorage.removeItem("visit", "x");
  }, [pageVisit]);

  return (
    <>
      <visitCounterContext.Provider value={{ pageVisit }}>
        {children}
      </visitCounterContext.Provider>
    </>
  );
};
