import styles from "./leadersCard.module.css";
import { FaLinkedin } from "react-icons/fa6";
import { BiLogoGmail } from "react-icons/bi";
import { Link } from "react-router-dom";

const LeadersCard = ({
  name,
  profile,
  image,
  linkedIn,
  gmail,
  linkedInStatus,
}) => {
  return (
    <div className={styles.card}>
      <img className={styles.circle_img} src={image} alt="avatar" />
      <div className={styles.cardBody}>
        <div className={styles.header}>
          <h2 className={styles.personName}>{name}</h2>
          <p>Director, RVSM</p>
          <h2>
            {linkedInStatus && (
              <>
                <span
                  onClick={() =>
                    window.open(
                      `https://linkedin.com/in/${linkedIn}/`,
                      "_blank"
                    )
                  }
                >
                  <FaLinkedin
                    color="rgb(10 102 194)"
                    style={{ cursor: "pointer" }}
                  />
                </span>
                &nbsp;&nbsp;
              </>
            )}
            <span
              onClick={() =>
                window.open(
                  `https://mail.google.com/mail/?view=cm&fs=1&to=${gmail}`,
                  "_blank"
                )
              }
            >
              <BiLogoGmail color="red" style={{ cursor: "pointer" }} />
            </span>
          </h2>
        </div>
        <p>{profile}</p>
      </div>
    </div>
  );
};

export default LeadersCard;
