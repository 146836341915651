import styles from "./networkManagementCard.module.css";

const NetworkManagementCard = ({ details }) => {
  const image = details;

  return (
    <>
      <div className={styles.container}>
        <img src={image} alt="telecom" className={styles.image1} />
      </div>
    </>
  );
};

export default NetworkManagementCard;
