import styles from "./solarImagesCard.module.css";
const SolarCard = ({ details }) => {
  return (
    <>
      <div className={styles.container}>
        <img src={details} className={styles.image} alt="solar" />
      </div>
    </>
  );
};

export default SolarCard;
