import { useState } from "react";
import styles from "./contact.module.css";
import { MdErrorOutline } from "react-icons/md";
import { toast } from "react-toastify";
const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    message: "",
  });

  const [errorMessageEmail, setErrorMessageEmail] = useState("");
  const [errorMessagePhone, setErrorMessagePhone] = useState("");
  const [formSubmitError, setFormSubmitError] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      if (!validatePhoneNumber(value)) {
        setErrorMessagePhone("Invalid phone number format.");
      } else {
        setErrorMessagePhone("");
      }
    }
    if (name === "email") {
      if (!validateEmail(value)) {
        setErrorMessageEmail("Please enter a valid email.");
      } else {
        setErrorMessageEmail("");
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validatePhoneNumber = (number) => {
    // Example validation: phone number should be in the format 123-456-7890
    const phoneRegex = /^\d{3}\d{3}\d{4}$/;
    return phoneRegex.test(number);
  };
  const validateEmail = (email) => {
    // Example validation: basic email format
    const emailRegex = /^[a-zA-Z0-9._%±]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  async function postFormData() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_PATH}/api/contact/contactUs`,
        {
          method: "POST", // Method can be 'GET', 'POST', etc.
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      // Parse the response data
      const responseData = await response.json();
      if (response.ok) {
        toast.success(responseData.message);
      } else {
        toast.error(responseData.message);
      }
    } catch (err) {
      setFormSubmitError(err.message);
      toast.error(err.message);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!errorMessageEmail && !errorMessagePhone) {
      await postFormData(formData);
      setFormData({
        name: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        state: "",
        message: "",
      });
    }
  };

  return (
    <>
      <div className={styles.container}>
        <h1>Contact Us</h1>
        <div className={styles.formContainer}>
          <form onSubmit={handleSubmit}>
            <div className={styles.formElements}>
              <label htmlFor="name">Full Name</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter your full name"
                value={formData.name}
                onChange={handleInputChange}
                autoCapitalize="sentences"
                autoFocus
                required
              />
            </div>
            <div className={styles.formElements}>
              <label htmlFor="email">Email Address</label>
              <input
                type="string"
                id="email"
                name="email"
                placeholder="Enter your email address"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
              <span style={{ color: "rgb(230, 28, 78)" }}>
                {errorMessageEmail ? <MdErrorOutline /> : ""}{" "}
                {errorMessageEmail}
              </span>
            </div>
            <div className={styles.formElements}>
              <label htmlFor="phone">Phone Number</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                placeholder="Enter your phone number"
                // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                maxLength="10"
                inputMode="numeric"
                value={formData.phone}
                onChange={handleInputChange}
                required
              />
              <span style={{ color: "rgb(230, 28, 78)" }}>
                {errorMessagePhone ? <MdErrorOutline /> : ""}{" "}
                {errorMessagePhone}
              </span>
            </div>
            <div className={styles.formElements}>
              <label htmlFor="address">Address</label>
              <input
                type="text"
                id="address"
                name="address"
                placeholder="Enter your address"
                value={formData.address}
                onChange={handleInputChange}
                autoCapitalize="sentences"
                required
              />
            </div>
            <div className={styles.formElements}>
              <label htmlFor="city">City</label>
              <input
                type="text"
                id="city"
                name="city"
                placeholder="Enter your city"
                value={formData.city}
                onChange={handleInputChange}
                autoCapitalize="sentences"
                required
              />
            </div>
            <div className={styles.formElements}>
              <label htmlFor="state">State</label>
              <input
                type="text"
                id="state"
                name="state"
                placeholder="Enter your state"
                value={formData.state}
                onChange={handleInputChange}
                autoCapitalize="sentences"
                required
              />
            </div>
            <div className={styles.formElements}>
              <label htmlFor="state">Enter Message</label>
              <textarea
                rows="10"
                cols="50"
                type="text"
                id="state"
                name="message"
                placeholder="Enter your message"
                value={formData.message}
                onChange={handleInputChange}
                autoCapitalize="sentences"
                required
              />
            </div>
            <div className={styles.btn}>
              <input type="submit" id={styles.submit} />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Contact;
