import { useContext } from "react";
import { planContext } from "../../../../store/getPlansContext";
import ChildOneGetAll from "./childOneGetAll";
import styles from "./getAllPlans.module.css";

const GetAllPlans = () => {
  const { speedWiseData, isLoading, isGeneralLoading } =
    useContext(planContext);

  return (
    <>
      {isLoading && isGeneralLoading ? (
        <div style={{ fontSize: "25px", height: "100vh" }}>
          <strong>Loading Data...</strong>
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.header}>
            <h1>High Speed Internet Service Plans</h1>
            <p>Choose from various options at an affordable cost.</p>
          </div>

          {speedWiseData.map((partner, i) => (
            <ChildOneGetAll key={i} partner={partner} />
          ))}
        </div>
      )}
    </>
  );
};

export default GetAllPlans;
