import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Leaders from "./components/About Us/Leaders Team/leaders/leaders";
import Navbar from "./components/navBar/navbar";
import Home from "./components/Home/home";
import Contact from "./components/Contact/contact";
import Support from "./components/Support/support";
import BroadbandServices from "./components/Services/Broadband Service/broadbandServices";
import CctvSurveillance from "./components/Services/CCTV Surveillance/cctvSurveillance";
import Strength from "./components/strength/strength";
import WebDevelopment from "./components/Services/Web Development/webdevelopment";
import PhotoGallery from "./components/Photo Gallery/photoGallery";
import TelecomProjects from "./components/Services/Telecom Projects/telecomProjects";
import NetworkManagement from "./components/Services/Network Management/networkManagement";
import ErrorPage from "./components/error Page/error";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      errorElement: <ErrorPage />,
      element: <Navbar />,
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: "about",
          element: <Leaders />,
        },
        {
          path: "services",
          children: [
            {
              path: "broadband/:id",
              element: <BroadbandServices />,
            },
            {
              path: "cctv/:id",
              element: <CctvSurveillance />,
            },
            {
              path: "web/:id",
              element: <WebDevelopment />,
            },
            {
              path: "telecom/:id",
              element: <TelecomProjects />,
            },
            {
              path: "network/:id",
              element: <NetworkManagement />,
            },
          ],
        },
        { path: "support", element: <Support /> },
        { path: "strength", element: <Strength /> },
        { path: "contact", element: <Contact /> },
        { path: "photo_gallery", element: <PhotoGallery /> },
      ],
    },
  ]);
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
