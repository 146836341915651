import { NavLink } from "react-router-dom";
import styles from "./error.module.css";

const ErrorPage = () => {
  return (
    <>
      <div className={styles.container}>
        <img
          src="/images/errorImg.jpg"
          alt="error"
          style={{ width: "100vw", height: "100vh" }}
        />
        <h1>404</h1>
        <h2>SORRY! PAGE NOT FOUND</h2>
        <p>
          Oops! It seems like the page you're trying to access doesn't exist .
        </p>
        <button>
          <NavLink to="/">Return to Home</NavLink>
        </button>
      </div>
    </>
  );
};

export default ErrorPage;
