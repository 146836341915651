import { IoLogoWhatsapp } from "react-icons/io";
import styles from "./whatsapp.module.css";
import { Link } from "react-router-dom";
const WhatsApp = () => {
  return (
    <>
      <div className={styles.container}>
        <Link to={`https://wa.me/919785329947`} target="_blank">
          <IoLogoWhatsapp
            color="green"
            size="30px"
            style={{ cursor: "pointer" }}
          />
        </Link>
      </div>
    </>
  );
};

export default WhatsApp;
