import Carousel from "../../Carousel/carousel";
import styles from "./cctvSurveillance.module.css";
import camera1 from "../../../static/images/camera Images/camera1.jpg";
import camera2 from "../../../static/images/camera Images/camera2.jpg";
import camera3 from "../../../static/images/camera Images/camera3.jpg";
import camera4 from "../../../static/images/camera Images/camera4.jpg";
import camera5 from "../../../static/images/camera Images/camera5.jpg";
import camera6 from "../../../static/images/camera Images/camera6.jpg";
import camera7 from "../../../static/images/camera Images/camera7.jpg";
import CctvImagesCard from "./cctv Images Card/cctvImagesCard";
import { GiCctvCamera } from "react-icons/gi";
import dwsImage from "../../../static/images/photoGallery/dwsProject.jpeg";
import rail from "../../../static/videos/railVideo1.mp4";
const CctvSurveillance = () => {
  const images = [camera1, camera2, camera3, camera4, camera6, camera7];
  return (
    <>
      <div className={styles.container}>
        <h1 className={styles.header}>CCTV Surveillance</h1>
        <Carousel images={images} interval={3000}>
          <CctvImagesCard />
        </Carousel>

        <div className={styles.contentContainer}>
          <div className={styles.contentDiv}>
            <h1 className={styles.contentHeader}>
              &ldquo;Providing safety and security is our number one priority.
              We make that possible with industry-leading CCTV cameras and
              systems suited for your every need.&rdquo;
            </h1>
            <div className={styles.content}>
              <p>
                RVSM offers top-of-the-line security camera systems for both
                businesses and homes.
              </p>
              <p>
                Our CCTV security cameras deliver exceptional performance with
                advanced features, including ultra-high resolution up to 4K,
                low-light illumination, powerful infrared night vision, and
                cutting-edge motion detection.
              </p>
              <p>
                You can easily view and playback CCTV camera video 24/7 on your
                smartphone, tablet, or computer. Most of our security cameras
                are hardwired to ensure optimal reliability and performance.
              </p>
            </div>
            <div className={styles.imageDiv}>
              <img src={camera5} alt="camera" />
            </div>
            <div className={styles.workExperience}>
              <h1 className={styles.contentHeader} id={styles.workHeader}>
                <GiCctvCamera />
                &nbsp;Work Experience
              </h1>
              <div className={styles.content} id={styles.workContent}>
                <p>
                  <strong>Client</strong> : Indian Railway Cctv Surveillance
                  Project
                </p>
                <p>
                  <strong>Project Year</strong> : May 2023
                </p>
              </div>
            </div>
            <div className={styles.videoDiv}>
              <video
                autoPlay
                muted
                loop
                controlsList="nofullscreen noremoteplayback nodownload"
                disablePictureInPicture
              >
                <source src={rail} type="video/mp4" />
                <p>This is cctv project video</p>
              </video>
            </div>
            <div className={styles.workExperience} id={styles.workExperience2}>
              <h1 className={styles.contentHeader} id={styles.workHeader}>
                <GiCctvCamera />
                &nbsp;Work Experience
              </h1>
              <div className={styles.content} id={styles.workContent}>
                <p>
                  <strong>Client</strong> : DWS Networking & Surveillance
                  project
                </p>
                <p>
                  <strong>Project Year</strong> : February 2021
                </p>
              </div>
            </div>
            <div className={styles.dwsProject} id={styles.workVideo2}>
              {/* <video
                autoPlay
                muted
                loop
                controlsList="nofullscreen noremoteplayback nodownload"
                disablePictureInPicture
              >
                <source src={cctvVideo} type="video/mp4" />
                <p>This is cctv project video</p>
              </video> */}
              <img src={dwsImage} alt="dws project" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CctvSurveillance;
