import styles from "./webdevelopment.module.css";
import WebDevelopmentCard from "./web Development Card/webDevelopmentCard";
import Carousel from "../../Carousel/carousel";
import canva1 from "../../../static/images/canva1.jpg";
import canva6 from "../../../static/images/canva6.jpg";
import canva3 from "../../../static/images/canva3.jpg";
import canva8 from "../../../static/images/canva8.jpg";
import { images, customData } from "./WebData/webData.js";
import { useState } from "react";
import mern from "../../../static/images/MERN.jpeg";
import devops from "../../../static/images/devOpsImage.png";

const WebDevelopment = () => {
  const [customContent, setCustomContent] = useState(null);
  const [showImage, setShowImage] = useState(0);
  const customImages = [canva1, canva6, canva3];

  const handleMouseEnter = (index) => {
    setCustomContent(index);
    setShowImage(index);
  };
  const handleMouseLeave = () => {
    setCustomContent(null);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.containerImg}>
          <img src={canva8} alt="web development" />
          <div className={styles.CarouselDiv}>
            <Carousel images={images} interval={2000}>
              <WebDevelopmentCard />
            </Carousel>
          </div>
        </div>
        <div className={styles.aboutUs}>
          <div className={styles.aboutUSAnimation} id={styles.animation1}></div>
          <div className={styles.aboutUSAnimation} id={styles.animation2}></div>
          <div className={styles.aboutUSAnimation} id={styles.animation3}></div>
          <h1>
            WE ARE <span style={{ color: "#024884" }}>R</span>
            <span style={{ color: "#c11d24" }}>VSM</span>
          </h1>
          <div className={`${styles.content}`}>
            <p>
              We design and develop websites that help our clients grow,
              innovate, and transform. We listen, learn and understand before we
              build.
            </p>
            <p>
              We are a team of developers, engineers, designers, and project
              managers who help you transform your idea into a working product.
            </p>
            <p>
              We deliver value to our clients with attention to detail and a
              customer-centric approach.
            </p>
            <p>
              Productive communication is one of our significant strength, and
              you will always have a transparent project pipeline, on-time
              product, delivery, and consultation support.
            </p>
          </div>
        </div>
        <div className={styles.customPanelBackground}>
          <h1>Our Web Design Services</h1>
          <div className={styles.customPanel}>
            <img
              src={customImages[showImage]}
              className={`${styles.customBackgroundImage}`}
              alt="background"
            />

            {customData.map((data, i) => (
              <div
                className={styles.customChild}
                onMouseEnter={() => handleMouseEnter(i)}
                onMouseLeave={handleMouseLeave}
              >
                <div
                  className={`${styles.overlay} ${
                    customContent === i ? styles.overlayVisible : ""
                  }`}
                ></div>
                <div className={styles.customChildHeading}>
                  <h2>{data.headCount}</h2>
                  <h3>{data.heading}</h3>
                </div>
                <div
                  className={`${styles.customChildContent} ${
                    customContent === i ? styles.visible : ""
                  }`}
                >
                  {customContent === i && <p>{data.content}</p>}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.webDesignContainerSm}>
          <h1>Our Web Design Services</h1>
          <div className={styles.child} id={styles.child1}>
            {/* <img src={canva1} alt="webdevelopment" /> */}
            <div className={styles.childContent}>
              <h2>
                <span style={{ color: "#04e4ff" }}>01</span> Custom Web Design &
                Development
              </h2>
              <p>
                We create websites that generate brand engagement, conversions
                and measurable results
              </p>
            </div>
          </div>
          <div className={styles.child} id={styles.child2}>
            {/* <img src={canva6} alt="eCommercedevelopment" /> */}
            <div className={styles.childContent}>
              <h2>
                <span style={{ color: "#04e4ff" }}>02</span> eCommerce Web
                Design & Development
              </h2>
              <p>
                We create superior online shopping experiences to boost revenue
                and grow brand loyalty.
              </p>
            </div>
          </div>
          <div className={styles.child} id={styles.child3}>
            {/* <img src={canva3} alt="webdesign" /> */}
            <div className={styles.childContent}>
              <h2>
                <span style={{ color: "#04e4ff" }}>03</span> Web App Design
              </h2>
              <p>
                We take a strategic approach to web app creation to provide
                intuitive user experiences for optimal engagement and user
                satisfaction.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.techContainer}>
          <h1>Our Technologies Stack</h1>
          <div className={styles.techContentContainer}>
            <div className={styles.techContent}>
              <h1>MERN Stack Development Services</h1>

              <p>
                We provide expert MERN stack development services to help
                businesses create modern and scalable web applications by
                leveraging the power of MongoDB, Express.js, React.js, and
                Node.js technologies.
              </p>
            </div>
            <div className={styles.techImage}>
              <img src={mern} alt="mernStack" />
            </div>
          </div>
        </div>
        <div className={styles.techContainer}>
          <h1>Our Technologies Stack</h1>
          <div className={styles.techContentContainer}>
            <div className={styles.techContent}>
              <h1>DevOps Services</h1>

              <p>
                At RVSM, our DevOps practices are pivotal to our success. By
                leveraging cutting-edge tools, fostering a collaborative
                culture, and continuously optimizing our processes, we deliver
                high-quality software that meets and exceeds our customers
                expectations.
              </p>
              <p>
                We have expertise in using Aws, Linux, Docker, Jenkins,
                Kubernetes for smooth deployment of web applications.
              </p>
            </div>
            <div
              className={styles.techImage}
              style={{ backgroundColor: "black" }}
            >
              <img src={devops} alt="devops" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebDevelopment;
