import { useContext } from "react";
import { visitCounterContext } from "../../store/getVisitCounterContext";
import styles from "./visitCounter.module.css";
const VisitCounter = () => {
  const { pageVisit } = useContext(visitCounterContext);

  return (
    <>
      <div className={styles.container}>
        <h1>Visitors : {pageVisit.visitCounts}</h1>
      </div>
    </>
  );
};

export default VisitCounter;
