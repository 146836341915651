import { NavLink } from "react-router-dom";
import styles from "./carouselCard.module.css";

const CarouselCard = ({ details }) => {
  const { service, serviceDetail, path } = details;
  return (
    <>
      <div className={styles.cardContainer}>
        <div className={styles.cardHeader}>
          <span>{service}</span>
        </div>
        <div className={styles.cardBody}>
          <p>{serviceDetail}</p>
          <NavLink to={path}>
            <button className={styles.btn}>Know more</button>
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default CarouselCard;
