import styles from "../who_we_are/whoWeAre.module.css";
import { IoPersonAddSharp } from "react-icons/io5";

const WhoWeAre = () => {
  return (
    <>
      <div className={styles.aboutUs}>
        <h1>About Us</h1>
      </div>
      <div className={styles.container}>
        {/* <h2 className={styles.header}>Who We Are</h2> */}
        <div className={styles.content}>
          <p>
            In 2019, our journey started as a RVS solution partnership firm. We
            established ourselves as a reliable and innovative provider of RVS
            solutions. As our reputation grew and our client base expanded, we
            realized the need to formalize our operations and establish as a
            more structured entity.
          </p>
          <p>
            In 2021, we took the significant step of registering our company
            under the name RVSM Solutions Pvt Ltd. This step allowed us to
            strengthen our commitment to delivering high-quality RVS solutions
            to our clients while also ensuring long-term growth and
            sustainability.
          </p>
          <p>
            RVSM Solutions Pvt Ltd is a leading company specializing in
            providing OFC Network solutions, Internet services, Surveillance,
            Web development and executing telecom projects. With a strong focus
            on delivering high-quality connectivity and Web development
            solutions, we have established ourselves as a reliable and
            innovative player in the telecommunications industry.
          </p>
          <p>
            We promise our customers a high quality, standardised and reliable
            service experience.Our commitment to technological advancements and
            customer satisfaction sets us apart, allowing us to cater to diverse
            clientele and contribute to the digital transformation of
            communities and businesses.
          </p>
        </div>
        <div className={styles.bottomContainer}>
          <div className={styles.paraContainer}>
            <p className={styles.bottomPara1}>
              <IoPersonAddSharp color="green" />
            </p>
            <p className={styles.bottomPara1}>3200+</p>
            <p className={styles.bottomPara2}>Happy Customers</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhoWeAre;
