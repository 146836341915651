import { NavLink, Outlet } from "react-router-dom";
import styles from "./navbar.module.css";
import logo from "../../static/images/Logo-Removebg-RVSM.png";
import { IoReorderThreeSharp } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import { useState } from "react";
import PageBottom from "../PageBottom/pageBottom";
import { ScrollToTop } from "../Scroll Component/scroll";
import WhatsApp from "../whatsapp/whatsapp";
import VisitCounter from "../visitCounter/visitCounter";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const [isServiceOpen, setServiceOpen] = useState(false);
  const handleToggle = (e) => {
    e.preventDefault();
    setToggle(!toggle);
  };

  const hide_ul = (e) => {
    e.preventDefault();
    setToggle(!toggle);
  };

  const toggleSerives = (e) => {
    e.preventDefault();
    setServiceOpen(!isServiceOpen);
  };

  const handleClickOnService = (e) => {
    // Custom logic to determine if navigation should be prevented
    const shouldNavigate = false; // Replace with your condition

    if (!shouldNavigate) {
      e.preventDefault();
    }
  };

  return (
    <>
      <ScrollToTop />
      <nav className={styles.navbar} id="navbar_top">
        <ul className={styles.navbarList}>
          <li className={styles.navbarItem}>
            <div className={styles.logo}>
              <NavLink to="/" onMouseEnter={() => setServiceOpen(false)}>
                <img src={logo} alt="logo" />
              </NavLink>
            </div>
          </li>
          <li className={styles.navbarItem}>
            <NavLink
              to="/"
              className={({ isActive }) => {
                if (isActive) {
                  return styles.side;
                } else return;
              }}
              onMouseEnter={() => setServiceOpen(false)}
            >
              Home
            </NavLink>
          </li>
          <li className={styles.navbarItem}>
            <NavLink
              to="/about"
              className={({ isActive }) => {
                if (isActive) {
                  return styles.side;
                } else return;
              }}
              onMouseEnter={() => setServiceOpen(false)}
            >
              About
            </NavLink>
          </li>
          <li className={styles.navbarItem}>
            <NavLink
              to="services"
              // style={{ color: "#fff" }}
              // className={isServiceOpen ? styles.side : ""}
              className={({ isActive }) => {
                if (isActive) {
                  return styles.side;
                } else return;
              }}
              onMouseEnter={(e) => {
                toggleSerives(e);
              }}
              onClick={handleClickOnService}
            >
              Services
            </NavLink>
            {isServiceOpen && (
              <ul
                className={styles.dropdownList}
                onMouseLeave={(e) => setServiceOpen(false)}
              >
                <li className={styles.dropdownItem}>
                  <NavLink
                    to="services/broadband/plans"
                    onClick={() => setServiceOpen(false)}
                  >
                    Broadband Services
                  </NavLink>
                </li>
                <li className={styles.dropdownItem}>
                  <NavLink
                    to="services/web/development"
                    onClick={() => setServiceOpen(false)}
                  >
                    Web Development
                  </NavLink>
                </li>
                <li className={styles.dropdownItem}>
                  <NavLink
                    to="services/cctv/Surveillance"
                    onClick={() => setServiceOpen(false)}
                  >
                    CCTV Surveillance
                  </NavLink>
                </li>
                <li className={styles.dropdownItem}>
                  <NavLink
                    to="services/telecom/projects"
                    onClick={() => setServiceOpen(false)}
                  >
                    Telecom Services
                  </NavLink>
                </li>
                <li className={styles.dropdownItem}>
                  <NavLink
                    to="services/network/management"
                    onClick={() => setServiceOpen(false)}
                  >
                    Network Management
                  </NavLink>
                </li>
              </ul>
            )}
          </li>
          <li className={styles.navbarItem}>
            <NavLink
              to="/support"
              className={({ isActive }) => {
                if (isActive) {
                  return styles.side;
                } else return;
              }}
              onMouseEnter={() => setServiceOpen(false)}
            >
              Support
            </NavLink>
          </li>
          <li className={styles.navbarItem}>
            <NavLink
              to="/strength"
              className={({ isActive }) => {
                if (isActive) {
                  return styles.side;
                } else return;
              }}
              onMouseEnter={() => setServiceOpen(false)}
            >
              Strength
            </NavLink>
          </li>
          <li className={styles.navbarItem}>
            <NavLink
              to="/contact"
              className={({ isActive }) => {
                if (isActive) {
                  return styles.side;
                } else return;
              }}
              onMouseEnter={() => setServiceOpen(false)}
            >
              Contact Us
            </NavLink>
          </li>
          <li className={styles.navbarItem}>
            <NavLink
              to="/photo_gallery"
              className={({ isActive }) => {
                if (isActive) {
                  return styles.side;
                } else return;
              }}
              onMouseEnter={() => setServiceOpen(false)}
            >
              Photo Gallery
            </NavLink>
          </li>
        </ul>
      </nav>
      {/* for smaller screens */}
      <nav className={styles.navbar_sm}>
        <div className={styles.navbarContainer}>
          <div className={styles.logo_sm}>
            <NavLink
              to="/"
              onClick={() => {
                setToggle(false);
              }}
              onMouseEnter={() => setServiceOpen(false)}
            >
              <img src={logo} alt="logo" />
            </NavLink>
          </div>
          <div className={styles.menu} onClick={(e) => handleToggle(e)}>
            Menu
            {toggle ? (
              <RxCross1 className={styles.textIcon} />
            ) : (
              <IoReorderThreeSharp className={styles.textIcon} />
            )}
          </div>
        </div>

        <div>
          {toggle && (
            <ul
              className={styles.nav_links_sm}
              onMouseLeave={() => {
                setServiceOpen(false);
                setToggle(false);
              }}
            >
              <li
                onClick={(e) => {
                  hide_ul(e);
                }}
                onMouseEnter={() => setServiceOpen(false)}
              >
                <NavLink
                  to="/"
                  className={({ isActive }) => {
                    if (isActive) {
                      return styles.side;
                    } else return;
                  }}
                >
                  Home
                </NavLink>
              </li>
              <li
                onClick={(e) => {
                  hide_ul(e);
                }}
                onMouseEnter={() => setServiceOpen(false)}
              >
                <NavLink
                  to="/about"
                  className={({ isActive }) => {
                    if (isActive) {
                      return styles.side;
                    } else return;
                  }}
                >
                  About
                </NavLink>
              </li>
              <li>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                  }}
                >
                  <NavLink
                    to="services"
                    className={({ isActive }) => {
                      if (isActive) {
                        return styles.side;
                      } else return;
                    }}
                    onMouseEnter={(e) => toggleSerives(e)}
                    onClick={handleClickOnService}
                  >
                    Services
                  </NavLink>

                  {isServiceOpen && (
                    <ul
                      className={styles.dropdownList_sm}
                      onMouseLeave={(e) => setServiceOpen(false)}
                    >
                      <li className={styles.dropdownItem_sm}>
                        <NavLink
                          to="services/broadband/plans"
                          onClick={() => {
                            setToggle(false);
                            setServiceOpen(false);
                          }}
                        >
                          Broadband Services
                        </NavLink>
                      </li>
                      <li className={styles.dropdownItem_sm}>
                        <NavLink
                          to="services/web/development"
                          onClick={() => {
                            setServiceOpen(false);
                            setToggle(false);
                          }}
                        >
                          Web Development
                        </NavLink>
                      </li>
                      <li className={styles.dropdownItem_sm}>
                        <NavLink
                          to="services/cctv/Surveillance"
                          onClick={() => {
                            setServiceOpen(false);
                            setToggle(false);
                          }}
                        >
                          CCTV Surveillance
                        </NavLink>
                      </li>

                      <li className={styles.dropdownItem_sm}>
                        <NavLink
                          to="services/telecom/projects"
                          onClick={() => {
                            setServiceOpen(false);
                            setToggle(false);
                          }}
                        >
                          Telecom Services
                        </NavLink>
                      </li>

                      <li className={styles.dropdownItem_sm}>
                        <NavLink
                          to="services/network/management"
                          onClick={() => {
                            setServiceOpen(false);
                            setToggle(false);
                          }}
                        >
                          Network Management
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </div>
              </li>
              <li
                onClick={(e) => {
                  hide_ul(e);
                }}
                onMouseEnter={() => setServiceOpen(false)}
              >
                <NavLink
                  to="/support"
                  className={({ isActive }) => {
                    if (isActive) {
                      return styles.side;
                    } else return;
                  }}
                >
                  Support
                </NavLink>
              </li>
              <li
                onClick={(e) => {
                  hide_ul(e);
                }}
                onMouseEnter={() => setServiceOpen(false)}
              >
                <NavLink
                  to="/strength"
                  className={({ isActive }) => {
                    if (isActive) {
                      return styles.side;
                    } else return;
                  }}
                >
                  Strength
                </NavLink>
              </li>
              <li
                onClick={(e) => {
                  hide_ul(e);
                }}
                onMouseEnter={() => setServiceOpen(false)}
              >
                <NavLink
                  to="/contact"
                  className={({ isActive }) => {
                    if (isActive) {
                      return styles.side;
                    } else return;
                  }}
                >
                  Contact Us
                </NavLink>
              </li>
              <li
                onClick={(e) => {
                  hide_ul(e);
                }}
                onMouseEnter={() => setServiceOpen(false)}
              >
                <NavLink
                  to="/photo_gallery"
                  className={({ isActive }) => {
                    if (isActive) {
                      return styles.side;
                    } else return;
                  }}
                >
                  Photo Gallery
                </NavLink>
              </li>
            </ul>
          )}
        </div>
      </nav>

      <Outlet />
      <WhatsApp />
      {/* <VisitCounter /> */}
      <PageBottom />
    </>
  );
};

export default Navbar;
