import Carousel from "../Carousel/carousel";
import BirthDayCard from "./birthdayCard/birthDayCard";
import styles from "./photoGallery.module.css";

import { ImagesData } from "./All Images Data/all_ImagesData";

const PhotoGallery = () => {
  return (
    <>
      <div className={styles.container}>
        {ImagesData.map((card, i) => (
          <div
            className={styles.birthDay}
            key={i}
            style={{ background: card.background }}
          >
            <div className={styles.birthdayCarousel}>
              <Carousel images={card.ImagesArray} interval={2000}>
                <BirthDayCard />
              </Carousel>
            </div>
            <div className={styles.birthdayContent}>
              <h1>{card.heading}</h1>
              <img src={card.cardImage} alt="bdayCard" />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default PhotoGallery;
