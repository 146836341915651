import { useEffect, useState } from "react";
import styles from "./popupForm.module.css";
import { ImCross } from "react-icons/im";
import { MdErrorOutline } from "react-icons/md";
import { toast } from "react-toastify";

const PopupForm = ({ setShowForm, sortedPlan }) => {
  const [selectedValue, setSelectedValue] = useState({
    planPrice: "",
    planDuration: "",
  });
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    broadbandSpeed: sortedPlan[0].speed,
    partner: sortedPlan[0].partner.partner,
  });

  const [errorMessageEmail, setErrorMessageEmail] = useState("");
  const [errorMessagePhone, setErrorMessagePhone] = useState("");
  const [errorMessagePlan, setErrorMessagePlan] = useState("");
  const [formSubmitError, setFormSubmitError] = useState("");
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      if (!validatePhoneNumber(value)) {
        setErrorMessagePhone("Invalid phone number format.");
      } else {
        setErrorMessagePhone("");
      }
    }
    if (name === "email") {
      if (!validateEmail(value)) {
        setErrorMessageEmail("Please enter a valid email.");
      } else {
        setErrorMessageEmail("");
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  async function postFormData() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_PATH}/api/customers/customer-data`,
        {
          method: "POST", // Method can be 'GET', 'POST', etc.
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      // Parse the response data
      const responseData = await response.json();
      if (response.ok) {
        toast.success(responseData.message);
      } else {
        toast.error(responseData.message);
      }
    } catch (err) {
      setFormSubmitError(err.message);
      toast.error(err.message);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!errorMessageEmail && !errorMessagePhone) {
      if (selectedValue.planPrice && selectedValue.planDuration) {
        formData.planPrice = selectedValue.planPrice;
        formData.planDuration = selectedValue.planDuration;

        await postFormData(formData);
        setShowForm(false);
      } else {
        setErrorMessagePlan("Please select plan duration");
      }
    }
  };
  const handleClose = () => {
    setShowForm(false);
  };

  const handleSelectedValue = (e) => {
    if (!(e.target.value === "Select Plan Duration...")) {
      setSelectedValue(JSON.parse(e.target.value));
      setErrorMessagePlan("");
    } else {
      setSelectedValue({
        planPrice: "",
        planDuration: "",
      });
    }
  };

  const validatePhoneNumber = (number) => {
    // Example validation: phone number should be in the format 123-456-7890
    const phoneRegex = /^\d{3}\d{3}\d{4}$/;
    return phoneRegex.test(number);
  };
  const validateEmail = (email) => {
    // Example validation: basic email format
    const emailRegex = /^[a-zA-Z0-9._%±]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  return (
    <>
      <div style={{ marginBottom: "100px" }} className={styles.formContainer}>
        <ImCross
          style={{
            marginLeft: "95%",
            color: "rgb(230, 28, 78)",
            cursor: "pointer",
          }}
          onClick={handleClose}
        />
        <form onSubmit={handleSubmit}>
          <div className={styles.formElements}>
            <label htmlFor="name">Full Name</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Enter your full name"
              value={formData.name}
              onChange={handleInputChange}
              autoCapitalize="sentences"
              autoFocus
              required
            />
          </div>
          <div className={styles.formElements}>
            <label htmlFor="email">Email Address</label>
            <input
              type="string"
              id="email"
              name="email"
              placeholder="Enter your email address"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            <span style={{ color: "rgb(230, 28, 78)" }}>
              {errorMessageEmail ? <MdErrorOutline /> : ""} {errorMessageEmail}
            </span>
          </div>
          <div className={styles.formElements}>
            <label htmlFor="phone">Phone Number</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="Enter your phone number"
              // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
              maxLength="10"
              inputMode="numeric"
              value={formData.phone}
              onChange={handleInputChange}
              required
            />
            <span style={{ color: "rgb(230, 28, 78)" }}>
              {errorMessagePhone ? <MdErrorOutline /> : ""} {errorMessagePhone}
            </span>
          </div>
          <div className={styles.formElements}>
            <label htmlFor="address">Address</label>
            <input
              type="text"
              id="address"
              name="address"
              placeholder="Enter your address"
              value={formData.address}
              onChange={handleInputChange}
              autoCapitalize="sentences"
              required
            />
          </div>
          <div className={styles.formElements}>
            <label htmlFor="city">City</label>
            <input
              type="text"
              id="city"
              name="city"
              placeholder="Enter your city"
              value={formData.city}
              onChange={handleInputChange}
              autoCapitalize="sentences"
              required
            />
          </div>
          <div className={styles.formElements}>
            <label htmlFor="state">State</label>
            <input
              type="text"
              id="state"
              name="state"
              placeholder="Enter your state"
              value={formData.state}
              onChange={handleInputChange}
              autoCapitalize="sentences"
              required
            />
          </div>
          <div className={styles.formElements}>
            <label htmlFor="speed">BroadBand Speed</label>
            <input
              type="text"
              id="speed"
              name="speed"
              value={formData.broadbandSpeed}
              disabled
            />
          </div>
          <div className={styles.formElements}>
            <span style={{ color: "rgb(230, 28, 78)" }}>
              {errorMessagePlan ? <MdErrorOutline /> : ""} {errorMessagePlan}
            </span>
            <label htmlFor="month">Plan Duration</label>
            <select
              onChange={handleSelectedValue}
              style={{
                fontSize: "15px",
                padding: "5px",
                borderRadius: "10px",
                outline: "none",
              }}
              required
            >
              <option>Select Plan Duration...</option>
              {sortedPlan.map((data, i) => (
                <option
                  value={`{"planPrice":${data.planPrice},"planDuration":"${data.planDuration}"}`}
                  key={i}
                >
                  {data.planDuration}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.formElements}>
            <label htmlFor="price">Price</label>
            <input
              type="text"
              id="price"
              name="price"
              value={`₹ ${selectedValue.planPrice}`}
              required
              disabled
            />
          </div>
          <div className={styles.formElements}>
            <label htmlFor="partner">Partner</label>
            <input
              type="text"
              id="partner"
              name="partner"
              value={formData.partner}
              disabled
            />
          </div>

          <div className={styles.btn}>
            <input type="submit" id={styles.submit} />
            <input
              type="button"
              value="Close"
              id={styles.close}
              onClick={handleClose}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default PopupForm;
