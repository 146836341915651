import bday1 from "../../../static/images/photoGallery/Birthday Celebration/bday1.jpeg";
import bday2 from "../../../static/images/photoGallery/Birthday Celebration/bday2.jpeg";
import bday3 from "../../../static/images/photoGallery/Birthday Celebration/bday3.jpeg";
import bday4 from "../../../static/images/photoGallery/Birthday Celebration/bday4.jpeg";
import bday5 from "../../../static/images/photoGallery/Birthday Celebration/bday5.jpeg";
import bday6 from "../../../static/images/photoGallery/Birthday Celebration/bday6.jpeg";
import bday7 from "../../../static/images/photoGallery/Birthday Celebration/bday7.jpeg";
import bdayCard from "../../../static/images/photoGallery/Birthday Celebration/bdayCard.jpg";

// ISP photos
import isp1 from "../../../static/images/photoGallery/isp/isp1.jpeg";
import isp2 from "../../../static/images/photoGallery/isp/isp2.jpeg";
import isp3 from "../../../static/images/photoGallery/isp/isp3.jpeg";
import isp4 from "../../../static/images/photoGallery/isp/isp4.jpeg";
import ispCard from "../../../static/images/photoGallery/isp/ispCard2.jpg";

// Railway servey
import rail1 from "../../../static/images/photoGallery/railway survey/rail1.jpeg";
import rail2 from "../../../static/images/photoGallery/railway survey/rail2.jpeg";
import rail3 from "../../../static/images/photoGallery/railway survey/rail3.jpeg";
import rail4 from "../../../static/images/photoGallery/railway survey/rail4.jpeg";
import rail5 from "../../../static/images/photoGallery/railway survey/rail5.jpeg";
import rail6 from "../../../static/images/photoGallery/railway survey/rail6.jpeg";
import rail7 from "../../../static/images/photoGallery/railway survey/rail7.jpeg";
import rail8 from "../../../static/images/photoGallery/railway survey/rail8.jpeg";
import rail9 from "../../../static/images/photoGallery/railway survey/rail9.jpeg";
import rail10 from "../../../static/images/photoGallery/railway survey/rail10.jpeg";
import rail11 from "../../../static/images/photoGallery/railway survey/rail11.jpeg";
import rail12 from "../../../static/images/photoGallery/railway survey/rail12.jpeg";
import cctvCard from "../../../static/images/photoGallery/railway survey/cctvCard.jpg";

//exhibition and marketing
import exhibition1 from "../../../static/images/photoGallery/marketing/exhibition1.jpeg";
import exhibition2 from "../../../static/images/photoGallery/marketing/exhibition2.jpeg";
import exhibition3 from "../../../static/images/photoGallery/marketing/exhibition3.jpeg";
import bsnlCard from "../../../static/images/photoGallery/marketing/bsnlCard.jpg";

//gift distribution

import gift1 from "../../../static/images/photoGallery/gift distribution/gift1.jpeg";
import gift2 from "../../../static/images/photoGallery/gift distribution/gift2.jpeg";
import gift3 from "../../../static/images/photoGallery/gift distribution/gift3.jpeg";
import gift4 from "../../../static/images/photoGallery/gift distribution/gift4.jpeg";
import gift5 from "../../../static/images/photoGallery/gift distribution/gift5.jpeg";
import giftCard from "../../../static/images/photoGallery/gift distribution/giftCard.jpg";

// website launch celebration

import web1 from "../../../static/images/website launch/web1.jpg";
import web2 from "../../../static/images/website launch/web2.jpeg";
import web3 from "../../../static/images/website launch/web3.jpeg";
import web4 from "../../../static/images/website launch/web4.jpg";
import web7 from "../../../static/images/website launch/web7.jpg";

import rvs_BSNL_Mela1 from "../../../static/images/rvs_BSNL_Mela_2024/rvs_BSNL_Mela1.jpeg";
import rvs_BSNL_Mela2 from "../../../static/images/rvs_BSNL_Mela_2024/rvs_BSNL_Mela2.jpeg";
import rvs_BSNL_Mela3 from "../../../static/images/rvs_BSNL_Mela_2024/rvs_BSNL_Mela3.jpeg";

export const ImagesData = [
  {
    ImagesArray: [rvs_BSNL_Mela3, rvs_BSNL_Mela1, rvs_BSNL_Mela2],
    heading: "RVS & BSNL Products Marketing Mela",
    cardImage: bsnlCard,
    background: `linear-gradient(
    #1f4037,
    #99f2c8)`,
  },
  {
    ImagesArray: [isp1, isp2, isp3, isp4],
    heading: "Congrats For Becoming ISP",
    cardImage: ispCard,
    background: `linear-gradient(45deg, #1e2222 50% , orange)`,
  },
  {
    ImagesArray: [web1, web2, web3, web4],
    heading: "Website Launch",
    cardImage: web7,
    background: `linear-gradient(45deg,#485563,#29323c)`,
  },
  {
    ImagesArray: [
      rail1,
      rail2,
      rail3,
      rail4,
      rail5,
      rail6,
      rail7,
      rail8,
      rail9,
      rail10,
      rail11,
      rail12,
    ],
    heading: "Railway CCTV Survey",
    cardImage: cctvCard,
    background: `linear-gradient(45deg, #2c3c4a 50% , #75c133)`,
  },
  {
    ImagesArray: [exhibition1, exhibition2, exhibition3],
    heading: "BSNL Fiber Campaign",
    cardImage: bsnlCard,

    background: `linear-gradient(45deg,#388791 40%,#c4dfe6)`,
  },
  {
    ImagesArray: [gift1, gift2, gift3, gift4, gift5],
    heading: "Gift Distribution",
    cardImage: giftCard,

    background: `linear-gradient(45deg,#1a2a6c,#b21f1f,#fdbb2d)`,
  },
  {
    ImagesArray: [bday2, bday3, bday1, bday4, bday5, bday6, bday7],
    heading: "Birthday Celebration",
    cardImage: bdayCard,

    background: `linear-gradient(45deg,#104a8b, #00042a)`,
  },
];
