import Carousel from "../../Carousel/carousel";
import styles from "./networkManagement.module.css";
import edan1 from "../../../static/images/Network management/edan1.jpg";
import edan2 from "../../../static/images/Network management/edan2.jpg";
import edanproject from "../../../static/videos/networkManagement.mp4";
import jecrcProject from "../../../static/images/Network management/epbax-removebg-preview.png";
import NetworkManagementCard from "./Network Management Card/networkManagementCard";

const NetworkManagement = () => {
  const images = [edan1, edan2, jecrcProject];
  return (
    <>
      <div className={styles.container}>
        <h1 className={styles.header}>Network Management Services</h1>
        <div className={styles.carouselContainer}>
          <Carousel images={images} interval={3000}>
            <NetworkManagementCard />
          </Carousel>
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.contentDiv}>
            <h1>Work Experience</h1>
            <h2>Client :- EDEN Garden Networking & Surveillance Project</h2>
            <h3>Project Year :- May 2023</h3>
            <p>
              RVSM, successfully designed and implemented high speed broadband
              connection on an optical fiber for 280+ customers.
            </p>
          </div>
          <div className={styles.videoDiv}>
            <video
              autoPlay
              muted
              loop
              controlsList="nofullscreen noremoteplayback nodownload"
              disablePictureInPicture
            >
              <source src={edanproject} type="video/mp4" />
              <p>Edan garden project</p>
            </video>
          </div>

          <div className={styles.contentDiv2}>
            <h1>Work Experience</h1>
            <h2>Client :- Networking & Voice Control with IP EPABX in JECRC</h2>
            <h3>Project Year :- Dec 2022</h3>
            <p>
              RVSM, successfully supply and commissioned Voice Solution by
              EPABX.
            </p>
          </div>
          <div className={styles.imageDiv}>
            <img src={jecrcProject} alt="jecrc project" />
          </div>
        </div>
      </div>
    </>
  );
};

export default NetworkManagement;
