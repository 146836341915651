import styles from "./support.module.css";
import switchImage from "../../static/images/switch2-min.jpg";
import switchPoster from "../../static/images/switch.jpg";
import robinVideo from "../../static/videos/support.mp4";
import { IoCall } from "react-icons/io5";
import { useState } from "react";

const Support = () => {
  const [toggle, setToggle] = useState(false);
  const handleToggle = () => {
    setToggle(!toggle);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <img src={switchImage} alt="switch" />
          <h1>Welcome to RVSM Support</h1>
        </div>
        <div className={styles.content}>
          <div className={styles.contentHeader}>
            <h1>
              Watch, learn, and <br />
              resolve your issues
            </h1>
            <h2>Check tutorial video for step-by-step guidance.</h2>
          </div>
          <div className={styles.mainContent}>
            {/* <h2>Syrotech GPON OLT Configuration on BSNL</h2> */}
            <video
              controls
              controlsList="nodownload noremoteplayback"
              poster={switchPoster}
              disablePictureInPicture
            >
              <source src={robinVideo} type="video/mp4" />
              <p>modem troubleshooting video</p>
            </video>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <h1>Still need to discuss something?</h1>
        <p>We’d love to help you.</p>
        <div className={styles.footbtnDiv}>
          <button onClick={handleToggle}>
            <IoCall /> Call us
          </button>
          {toggle && (
            <div id={styles.phnNumdiv}>
              Phone Numbers:- +91 9785329947, +0141-2945700
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Support;
