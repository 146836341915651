import LeadersCard from "../leaders Card/leadersCard";
import WhoWeAre from "../../who_we_are/whoWeAre";
import styles from "./leaders.module.css";
import sk from "../../../../static/images/sk.JPG";
import rk from "../../../../static/images/RK.jpeg";
import vij from "../../../../static/images/vijendra.jpeg";
import mun from "../../../../static/images/munesh.jpeg";
const Leaders = () => {
  const leadersInfo = [
    {
      name: "RK Singh",
      profile: `Ramesh Kumar Singh is responsible for Fibre Network at RVSM . He has 20+ years of  experience in 
      Telecom and Fibre 
      Network with Railtel, 
      Vodafone, Airtel & 
      Reliance, Rajnet Project, 
      Smart City Project, FTTH 
      Project.`,
      image: rk,
      linkedIn: "",
      gmail: "rameshkumarsingh71@gmail.com",
      linkedInStatus: false,
    },

    {
      name: "SK Sharma",
      profile: `Santosh Kumar Sharma leads Admin and Account department at RVSM. He has 17+ years of experience in 
    Purchase & Supply chain, 
    IPTV Project , Rajnet
    Project, FTTH Project, 
    Smart City Project.`,
      image: sk,
      linkedIn: "sksharma82",
      gmail: "rvsmsolutionspvtltd@gmail.com",
      linkedInStatus: true,
    },
    {
      name: "Vijendra Singh",
      profile: `Vijendra Singh is responsible for Information Technology and operations at RVSM. He has 19+ years of experience 
    in Networking with 
    IPTV Project, FTTH 
    Project, Smart City 
    Project.`,
      image: vij,
      linkedIn: "vijendra-singh-20348215",
      gmail: "vijendra12k@gmail.com",
      linkedInStatus: true,
    },
    {
      name: "Munesh Goswami",
      profile: `Munesh Goswami leads Marketing and distribution of a product or service at RVSM. He has 12+ years of experience 
      in Technical & 
      Marketing with IPTV 
      & FTTH Project.`,
      image: mun,
      linkedIn: "",
      gmail: "munesh.goswami@gmail.com",
      linkedInStatus: false,
    },
  ];
  return (
    <>
      <WhoWeAre />
      <div className={styles.leaderContainer}>
        <h2 className={styles.header}>Our Leadership Team</h2>
        <div className={styles.container}>
          {leadersInfo.map((leader, i) => (
            <LeadersCard
              key={i}
              name={leader.name}
              profile={leader.profile}
              image={leader.image}
              linkedIn={leader.linkedIn}
              gmail={leader.gmail}
              linkedInStatus={leader.linkedInStatus}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Leaders;
