import { useContext, useState } from "react";
import ChildTwoGetAll from "./childTwoGetAll";
import { planContext } from "../../../../store/getPlansContext";
import styles from "./childOneGetAll.module.css";

const ChildOneGetAll = ({ partner }) => {
  const temp1 = partner[0];
  const temp2 = temp1[0];
  const { generalData } = useContext(planContext);

  let partnerGeneralData = generalData.filter(
    (part) => part.partner === temp2.partner._id
  );

  //
  if (partnerGeneralData.length < 1) {
    partnerGeneralData = [
      {
        ontInstallationCharges: "",
        installationCharges: "",
        staticIPCharges: "",
        color1: "#ffffff",
        color2: "#ffffff",
        colorRange1: "50",
        colorRange2: "50",
      },
    ];
  }

  return (
    <>
      <div className={styles.container}>
        <h2 className={styles.header}>
          FTTH PLANS WITH {temp2.partner.partner}
        </h2>

        <div className={styles.cardContainer}>
          {partner.map((plan, i) => (
            <ChildTwoGetAll
              key={i}
              plan={plan}
              partnerGeneralData={partnerGeneralData}
              partner={temp2.partner.partner}
            />
          ))}
        </div>
        <div className={styles.staticContainer}>
          <p>
            <strong>Static IP Charges Yearly :</strong> ₹{" "}
            {partnerGeneralData[0].staticIPCharges}
            /-
          </p>
          <p>
            <strong>*WI-FI ONT :</strong> ₹{" "}
            {partnerGeneralData[0].ontInstallationCharges}/- (One Time,
            Non-refundable)
          </p>
          <p>
            <strong>Installation Charges :</strong> ₹{" "}
            {partnerGeneralData[0].installationCharges}
            /-
          </p>
        </div>
      </div>
    </>
  );
};

export default ChildOneGetAll;
