import styles from "./solarProjects.module.css";
import solar1 from "../../../static/images/solar1.jpg";
import solar2 from "../../../static/images/solar2.jpg";
import solar3 from "../../../static/images/solar3.jpeg";
import solar4 from "../../../static/images/solar4.jpeg";
import solar5 from "../../../static/images/solar5.jpeg";
import solar7 from "../../../static/images/solar7.jpg";
import adani from "../../../static/images/adaniPower.png";
import Carousel from "../../Carousel/carousel";
import SolarCard from "./solarCarousel/solarImagesCard";
import solarVideo from "../../../static/videos/solarVideo.mp4";

const SolarProject = () => {
  const images = [solar1, solar2, solar3, solar4, solar5, solar7];
  return (
    <>
      <div className={styles.container}>
        <h1 className={styles.header}>Solar Project</h1>
        <Carousel images={images} interval={3000}>
          <SolarCard />
        </Carousel>
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <h1>
              Client :&nbsp;
              <img src={adani} alt="adani power" />
            </h1>
            <h3>Project Year: January, 2024</h3>
            <p>
              The future of solar energy looks promising due to continuous
              advancements in technology, decreasing costs, and increasing
              efficiency of solar panels.
            </p>
            <p>
              Innovations such as solar photovoltaic cells, solar thermal energy
              systems, and energy storage solutions are making solar energy more
              viable and accessible.
            </p>
            <p>
              Governments worldwide are also supporting solar energy through
              subsidies, tax incentives, and renewable energy targets.
            </p>
          </div>
          <div className={styles.videoDiv}>
            <video
              autoPlay
              muted
              loop
              controlsList="nofullscreen noremoteplayback nodownload"
              disablePictureInPicture
            >
              <source src={solarVideo} type="video/mp4" />
              <p>This is solar project video</p>
            </video>
          </div>
        </div>
      </div>
    </>
  );
};

export default SolarProject;
