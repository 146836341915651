export const images = [
  {
    line1: "We Create",
    line2: "Stunning",
    line3: "Websites",
  },
  {
    line1: "We Design",
    line2: "Unique",
    line3: "Brand Identities",
  },
  {
    line1: "We Craft",
    line2: "Great",
    line3: "User Experience",
  },
  {
    line1: "We Build",
    line2: "Robust",
    line3: "Web Applications",
  },
];

export const customData = [
  {
    headCount: "01",
    heading: "Custom Web Design & Development",
    content: `We create websites that generate brand engagement, conversions and
            measurable results`,
  },
  {
    headCount: "02",
    heading: "eCommerce Web Design & Development",
    content:
      "We create superior online shopping experiences to boost revenue and grow brand loyalty.",
  },
  {
    headCount: "03",
    heading: "Web App Design",
    content:
      "We take a strategic approach to web app creation to provide intuitive user experiences for optimal engagement and user satisfaction.",
  },
];
