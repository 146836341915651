import styles from "./birthDayCard.module.css";
const BirthDayCard = ({ details }) => {
  const image = details;
  return (
    <>
      <div className={styles.container}>
        <img src={image} alt="birthday" />
      </div>
    </>
  );
};

export default BirthDayCard;
