import Carousel from "../../Carousel/carousel";
import styles from "./telecomProjects.module.css";
import bharatnet from "../../../static/images/telecom project/bharatnet.jpg";
import tel1 from "../../../static/images/telecom project/telcom1.jpg";
import tel2 from "../../../static/images/telecom project/telcom2.jpg";
import tel3 from "../../../static/images/telecom project/telcom3.jpg";
import bharat2 from "../../../static/images/telecom project/bharat2.jpg";
import TelecomCard from "./TelecomCard/telecomCard";

const TelecomProjects = () => {
  const images = [bharatnet, tel1, tel2, tel3];
  return (
    <>
      <div className={styles.container}>
        <h1 className={styles.header}>Telecom Project</h1>
        <div className={styles.carouselContainer}>
          <Carousel images={images} interval={3000}>
            <TelecomCard />
          </Carousel>
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.contentDiv}>
            <h1>Work Experience :- BHARATNET PROJECT</h1>
            <span className={styles.projectStatus}>
              Project Year: July 2022
            </span>
            <p>
              RVSM successfully completed troubleshooting and maintenance of
              BHARATNET PROJECT optical fibers of 376 Gram Panchayats in 9
              Blocks in Rajasthan Region.
            </p>
            <p>
              BharatNet, one of the biggest rural telecom projects in the world,
              implemented in a phased manner to all Gram Panchayats
              (approximately 2.5 lakh) in the country for providing
              non-discriminatory access to broadband connectivity to all the
              telecom service providers.
            </p>
            <p>
              Objective is to enable access providers like mobile operators,
              Internet Service Providers (ISPs), Cable TV operators, content
              providers to launch various services such as applications like
              e-health, e-education and e-governance in rural and remote India.
            </p>
          </div>
          <div className={styles.imageDiv}>
            <img src={bharat2} alt="workers" />
          </div>
        </div>
      </div>
    </>
  );
};

export default TelecomProjects;
