import styles from "./webdevelopmentCard.module.css";

const WebDevelopmentCard = ({ details }) => {
  const image = details;
  return (
    <>
      <div className={styles.container}>
        <div className={styles.paraDiv}>
          <p>
            {image.line1}
            <br />
            {image.line2}
            <br />
            {image.line3}
            <br />
          </p>
          <div className={styles.overlay} id={styles.overlay1}></div>
          <div className={styles.overlay} id={styles.overlay2}></div>
          <div className={styles.overlay} id={styles.overlay3}></div>
        </div>
      </div>
    </>
  );
};

export default WebDevelopmentCard;
