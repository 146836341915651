import VideoBackground from "../backgroundVideo/backgroundVideo";
import AboutCompany from "./About Company/aboutComapny";

import styles from "./home.module.css";

const Home = () => {
  return (
    <>
      <div className={styles.container}>
        <VideoBackground />
        <AboutCompany />
      </div>
    </>
  );
};

export default Home;
