import { useState } from "react";
import styles from "./childTwoGetAll.module.css";
import PopupForm from "../Popup Form/popupForm";

const ChildTwoGetAll = ({ plan, partnerGeneralData, partner }) => {
  const [selectedValue, setSelectedvalue] = useState(plan[0].planPrice);

  const { color1, color2, colorRange1, colorRange2 } = partnerGeneralData[0];
  const [showForm, setShowForm] = useState(false);
  const sortedPlan = plan.sort((a, b) => {
    // Extract the numerical part of the strings
    let numA = parseInt(a.planDuration.split(" ")[0]);
    let numB = parseInt(b.planDuration.split(" ")[0]);

    return numA - numB;
  });

  const handleSelectedValue = (e) => {
    setSelectedvalue(e.target.value);
  };

  return (
    <>
      {showForm && <div className={styles.overlay}></div>}

      <div
        className={styles.cardContainer}
        style={{
          background: `linear-gradient(45deg,${color1} ${colorRange1}%,${color2} ${colorRange2}%)`,
        }}
      >
        <div className={styles.header}>
          <h2>Speed @{sortedPlan[0].speed}</h2>
          {partner == "BHARAT SANCHAR NIGAM LIMITED (BSNL)" ? (
            `1 month plan available for new customers`
          ) : (
            <p>1/3/6/12 months plans available for new customers</p>
          )}

          <h3>
            Select Duration&nbsp;&nbsp;&nbsp;
            <select onChange={handleSelectedValue}>
              {sortedPlan.map((data, i) => (
                <option value={data.planPrice} key={i}>
                  {data.planDuration}
                </option>
              ))}
            </select>
          </h3>
        </div>
        <hr />
        <div className={styles.cardBody}>
          <p id={styles.price1}>Price</p>
          <p id={styles.data1}>Data</p>
          <p id={styles.price2}>₹ {selectedValue}</p>
          <p id={styles.data2}>@{sortedPlan[0].speed}</p>
        </div>
        <div className={styles.footer}>
          <button
            onClick={() => {
              setShowForm(true);
            }}
          >
            Book Now
          </button>
        </div>
      </div>

      {showForm && (
        <PopupForm setShowForm={setShowForm} sortedPlan={sortedPlan} />
      )}
    </>
  );
};

export default ChildTwoGetAll;
