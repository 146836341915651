import styles from "./aboutCompany.module.css";
import { FaMapPin } from "react-icons/fa6";
import logo from "../../../static/images/Logo-Removebg-RVSM.png";

const AboutCompany = () => {
  return (
    <>
      <div className={styles.container}>
        <img src={logo} alt="logo" className={styles.bgLogo} />
        <h1 className={styles.header}>
          &ldquo;Committed to smooth life with innovation at the core&rdquo;
        </h1>
        <div className={styles.content}>
          <div className={styles.para1}>
            <FaMapPin color="rgb(216, 78, 78)" fontSize="25px" />
            <p>
              We believes in building long-term relationships with our customers
              by prioritizing their needs and providing exceptional service. Our
              customer-centric approach drives us to understand their
              requirements, offer customized solutions, and provide exceptional
              support.
            </p>
          </div>
          <div className={styles.para2}>
            <FaMapPin color="rgb(216, 78, 78)" fontSize="25px" />
            <p>
              Through effective communication channels and responsive customer
              care, we strive to exceed expectations and foster strong
              partnerships. All our processes are geared towards producing the
              best services in terms of quality, functionality and
              affordability. We are geared towards not just customer
              satisfaction but strive towards achieving customer delight.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutCompany;
