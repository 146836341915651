import GetAllPlans from "./BroadbandPlans/getAllPlans";
import styles from "./broadbandServices.module.css";

const BroadbandServices = () => {
  return (
    <>
      <div className={styles.container}></div>
      <GetAllPlans />
    </>
  );
};

export default BroadbandServices;
